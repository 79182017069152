import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import connectVideo from "../../assets/images/Video/Connectar08.e09952939f4fa6f9965f.mp4";
import Videoplayer from "../Videoplayer";
import BannerImage from "../../assets/images/confrence/banner-img.svg";
import "./event.css";
import whyConnectar from "../../assets/images/confrence/why_the-connector.svg";
import Organisation from "../../assets/images/confrence/what-we-do.svg";
import referencability from "../../assets/images/confrence/referencability.svg";
import Methodology from "../../assets/images/confrence/methodology.svg";
import empathyIcon from "../../assets/images/FrontPage/empathy.svg";
import reflectionIcon from "../../assets/images/FrontPage/brain.svg";
import communicationIcon from "../../assets/images/FrontPage/communication.svg";
import socialCognitionIcon from "../../assets/images/FrontPage/social-cognition.svg";
import ideaIcon from "../../assets/images/FrontPage/idea.svg";
import altruismIcon from "../../assets/images/FrontPage/hand-holding-user.png";
import Avatar from "../../assets/images/FrontPage/avatar-group.png";

import { NavLink } from "react-router-dom";
import Footer from "./Footer";
const MainPage = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const videoRef = useRef(null);
  const videoPlayRef = useRef(null);
  const [showHowWorkModal, setShowHowWorkModal] = useState(false);
  const isFirstVisit = localStorage.getItem("isFirstTimeVisit");

  // useEffect(() => {
  //   if (!isFirstVisit) {
  //     localStorage.setItem("isFirstTimeVisit", true);
  //     setShowVideoModal(true);
  //   }

  //   const handleEscKey = (event) => {
  //     if (event.keyCode === 27) closeVideoModal();
  //   };
  //   document.addEventListener("keydown", handleEscKey);

  //   return () => {
  //     document.removeEventListener("keydown", handleEscKey);
  //   };
  // }, [isFirstVisit]);

  // const openVideoModal = () => {
  //   setShowVideoModal(true);
  //   setVideoPlayed(true);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //     videoRef.current.play();
  //   }
  // };
  // const closeVideoModal = () => {
  //   setShowVideoModal(false);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //     videoRef.current.pause();
  //   }
  // };

  // const openWorkModal = () => {
  //   setShowHowWorkModal(true);
  //   if (videoPlayRef.current) {
  //     videoPlayRef.current.currentTime = 0;
  //     videoPlayRef.current.play();
  //   }
  // };

  // const closeWorkModal = () => {
  //   setShowHowWorkModal(false);
  //   if (videoPlayRef.current) {
  //     videoPlayRef.current.pause();
  //     videoPlayRef.current.currentTime = 0;
  //   }
  // };
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      {/* darker #7DAF82 */}
      <Header />
      <figure>
        <section className="container">
          <section className="row align-items-center" data-aos="fade-right">
            <section className="col-sm-6 bannerContent">
              <h1>
                The <span className="heading-col">Connectar </span>
              </h1>
              <h3>Conference and Event Connection Platform</h3>
              <p className="font justify-text">
                Helping your event or conference participants improve engagement, collaboration, and
                overall event success by fostering meaningful connections among attendees,
                regardless of their industry, role, or geographic location. Through strategic
                networking, we help individuals thrive both professionally and socially,
                contributing to a more engaging and impactful event experience.
              </p>

              {/* <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <button
                  className="btn btn-success d-inline-flex align-items-center main-btn"
                  onClick={openVideoModal}
                >
                  Why Connectar{" "}
                  <i
                    class="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "25px" }}
                  ></i>{" "}
                </button>
                <button
                  className="btn btn-success d-inline-flex align-items-center main-btn"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "25px" }}
                  ></i>
                </button>
              </div> */}
            </section>
            <section class="col-sm-6">
              <img src={BannerImage} alt="" />
            </section>
          </section>
        </section>
        {/* Video Modal */}

        {/* {showVideoModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeVideoModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                    <video
                      ref={videoRef}
                      id="videoPlayer"
                      title="Video Title"
                      className="videoframe"
                      loop
                      autoPlay
                      controls
                      allow="autoplay; fullscreen;"
                      allowFullScreen
                      preload="none"
                      muted={!videoPlayed}
                      onCanPlay={() => videoRef.current?.play()}
                    >
                      <source src={connectVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {showHowWorkModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeWorkModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                    <Videoplayer ref={videoPlayRef} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )} */}
      </figure>

      <section class="section-widget success-col mb-4 " style={{ backgroundColor: "#687D6c" }}>
        <section class="container">
          <section class="row text-center">
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={altruismIcon} alt="" />
              </i>
              <h4 class="mb-2">Enhanced Professional Collaboration</h4>
              <p>
              The Connectar facilitates meaningful connections among event attendees, enabling networking, peer learning, and collaboration. By connecting participants with industry professionals and mentors, it fosters innovation, enhances problem-solving, and unlocks future business opportunities.

              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={ideaIcon} alt="" />
              </i>
              <h4 class="mb-2">Improved Well-being and Event Satisfaction</h4>
              <p>
              The Connectar helps attendees feel supported and less isolated, especially in virtual or first-time settings. By fostering valuable social and professional networks, it reduces stress and enhances the overall event experience, creating a more fulfilling and satisfying atmosphere.
              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={communicationIcon} style={{ color: "#A8CAAE" }} alt="" />
              </i>
              <h4 class="mb-2">Increased Engagement and Retention</h4>
              <p>
              The Connectar cultivates a sense of belonging among attendees, encouraging active participation and loyalty to future events. By connecting like-minded individuals, it enhances engagement during sessions and workshops while improving overall attendee satisfaction.
              </p>
            </section>
          </section>
        </section>
      </section>

      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={whyConnectar} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                Why <span className="heading-col">The Connectar</span>
              </h2>
              <p className="font justify-text">
                Improved connection among event attendees offers a wide range of benefits,
                contributing not only to event success but to the long-term professional development
                of participants. Here are several reasons why connection is key to enhancing any
                event.
              </p>
              <NavLink to="/conference/why-the-connectar" className="btn btn-success">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center  order-md-2">
              <img src={referencability} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                What does the <span className="heading-col">data tell us?</span>
              </h2>
              <p className="font justify-text">
                The benefits of fostering meaningful connections at conferences and events are
                backed by extensive research and data. Studies consistently show that networking and
                collaboration are not only essential for attendee satisfaction but also for the
                long-term success of both individuals and organizations. From enhancing engagement
                and knowledge sharing to boosting innovation and reducing stress, the data
                underscores the pivotal role that connections play in making events more impactful.
                By leveraging the power of networking, event organizers can create more valuable
                experiences, ensuring greater satisfaction, retention, and business outcomes. Below
                are key insights that demonstrate the measurable benefits of prioritizing attendee
                connection at your next event.
              </p>
              <NavLink to="/conference/why-you-should" className="btn btn-success">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={Methodology} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-col">The Connectar</span> Methodology
              </h2>
              <p className="font justify-text">
                At The Connectar, we are focused on helping event attendees make meaningful
                connections that last beyond the event. By fostering an environment of
                collaboration, support, and shared goals, we ensure that attendees can maximize
                their networking and gain the most value from each event experience.
              </p>
              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/conference/methodology" className="btn btn-success">
                  Read More
                </NavLink>
                {/* <button
                  className="btn btn-success d-inline-flex align-items-center"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "22px" }}
                  ></i>
                </button> */}
              </div>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center order-2">
              <img src={Organisation} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                Why you should <span className="heading-col">do something now</span>
              </h2>
              <p className="font justify-text">
                As the world adapts to new ways of working and collaborating, the need for
                meaningful connections at conferences and events has never been more crucial. With
                the rise of hybrid and virtual events, while technology offers convenience, it can
                also lead to a sense of disconnection. Attendees may feel isolated, missing out on
                the full experience of networking and engaging with peers. However, those who
                embrace connection-building strategies can transform this challenge into an
                opportunity to enhance event value and participant satisfaction.
              </p>
              <NavLink to="/conference/whyNow" className="btn btn-success">
                Read More
              </NavLink>
            </div>
          </div>
        </section>
      </section>

      <section
        className="section-widget  color-white Benefits mt-5"
        style={{ backgroundColor: "#687D6c" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={empathyIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Career Advancement</h3>
                  <p className="font justify-text">
                  The Connectar creates pathways to career growth by connecting attendees with industry professionals, recruiters, and mentors. These relationships open doors to job opportunities, internships, and guidance that enhance career trajectories.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Empathy--> */}

            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={reflectionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Cultural Exchange and Diversity</h3>
                  <p className="font justify-text">
                  The Connectar promotes inclusivity by fostering connections among attendees from diverse backgrounds. It creates opportunities for cultural exchange, global connections, and the development of an inclusive environment where every voice is valued.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Capacity for Reflection and Self-awareness--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={socialCognitionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Increased Innovation and Creativity</h3>
                  <p className="font justify-text">
                  The Connectar drives innovation by connecting attendees from different sectors and backgrounds. These connections spark new ideas, enable the sharing of best practices, and encourage collaborative problem-solving for industry challenges.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Social Cognition--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={ideaIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Increased Engagement in Event Activities</h3>
                  <p className="font justify-text">
                  The Connectar inspires attendees to actively participate in event activities, from workshops to networking sessions. By strengthening connections, it encourages leadership roles and fosters long-term networks that continue beyond the event.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Cultural and Emotional Intelligence--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={altruismIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Better Event Experience</h3>
                  <p className="font justify-text">
                  The Connectar transforms events by encouraging interactive sessions and resource sharing. By facilitating post-event collaborations, it ensures attendees derive maximum value and continue to build on the relationships formed during the event.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Altruism and Cooperation--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={communicationIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Promotion of Inclusion and Equal Opportunities</h3>
                  <p className="font justify-text">
                  The Connectar connects diverse attendees, fostering an inclusive and welcoming environment. By ensuring marginalized groups have a voice and access to opportunities, it creates equitable professional and personal growth opportunities for all.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Communication Skills--> */}
          </div>
        </div>
      </section>
      <section className="section-widget">
        <div className="container">
          <div className="getInTouch text-center" data-aos="fade-right">
            <i className="d-block mb-3">
              <img src={Avatar} alt="" />
            </i>
            <h5 className="interFont">Still have questions?</h5>
            <p className="font ">
              If you have any questions, or you feel this may benefit your organisation; please
              reach out to us here.
            </p>
            <NavLink to="/conference/contact">
              <button class="btn btn-success">Get in touch</button>
            </NavLink>
          </div>
        </div>
      </section>
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default MainPage;
