import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import conferences from "../../assets/images/confrence/conferences.svg";
import questionnaire from "../../assets/images/confrence/question.svg";
import connections from "../../assets/images/confrence/connections.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./event.css";

import howItWork from "../../assets/images/Video/TheConnectarFinal.mp4";
import videoThumbnail from "../../assets/images/Video/thumbnail.png";

const Confrenecmethodoloy = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
    const video = videoRef.current;
    if (video) {
      video.load();
    }
  }, []);
  return (
    <>
      <Header />
      <section class="border1">
        <section className="container">
          <section className="row align-items-center" data-aos="fade-right">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                <span className="heading-col"> The Connectar </span> Methodology
              </h2>
              <p class="justify-text font">
                At The Connectar, we are focused on helping event attendees make meaningful
                connections that last beyond the event. By fostering an environment of
                collaboration, support, and shared goals, we ensure that attendees can maximize
                their networking and gain the most value from each event experience. How do we do
                this?
              </p>
            </section>
            {/* <section class="col-sm-6 video-container">
              <div
                class="video-wrapper"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <video
                  ref={videoRef}
                  poster={videoThumbnail}
                  class="videoframe"
                  preload="auto"
                  loop
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                >
                  <source src={howItWork} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="play-pause-btn" onClick={togglePlayPause}>
                  {isPlaying ? (
                    <></>
                  ) : (
                    <i className="fa fa-play-circle-o video_icon" aria-hidden="true"></i>
                  )}
                  {isHovered && isPlaying && (
                    <div className="pause-icon">
                      <i className="fa fa-pause-circle-o video_icon1" aria-hidden="true"></i>
                    </div>
                  )}
                </div>
              </div>
            </section> */}
          </section>
        </section>
      </section>
      <section class="container">
        <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6">
            <img src={conferences} alt="" style={{height:"250px"}}  />
          </section>
          <section class="col-sm-6 ">
           
            <p class="justify-text font">
              Through our proprietary Pollen Pairing algorithm, we match attendees based on shared
              interests, personality, intelligence, and values. We understand that conferences and
              events can feel overwhelming, and it can be hard to find the right people to connect
              with. The Connectar ensures that connections are purposeful and impactful, providing
              the best possible outcomes for both personal and professional growth.
            </p>
          </section>
        </section>
      </section>

      <section class="container">
        <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6 bannerContent">
            <p class="justify-text font">
              Our process begins with a simple questionnaire that collects information about what
              makes each attendee’s unique, and then we ensure the delegates are connected based on
              their compatibility, ensuring successful, meaningful long term relationships are
              forged. This data is securely processed using our algorithm to connect attendees who
              are most likely to benefit from meaningful interactions.
            </p>
          </section>
          <section class="col-sm-6">
            <img src={questionnaire} alt="" style={{height:"250px"}}  />
          </section>
        </section>
      </section>

      <section class="container">
        <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6">
            <img src={connections} alt="" style={{height:"250px", width:"400px"}}  />
          </section>
          <section class="col-sm-6 bannerContent">
            <p class="justify-text font">
              Once we’ve made the connections, we facilitate introductions via email or calendar
              invite and encourage attendees to meet in person or virtually, fostering valuable
              discussions and collaborations. These initial connections often lead to new business
              partnerships, mentorships, and long-lasting professional relationships.
            </p>
          </section>
        </section>
      </section>

      <GetInTouch />
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default Confrenecmethodoloy;
