import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import culture from "../assets/images/FrontPage/culture.jpg"
import authentication from "../assets/images/FrontPage/2fa-authentication.avif"
import knowledgesharing from "../assets/images/FrontPage/Employeeretention.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'MainComponet/css/custom.css'
import howItWork from "../assets/images/Video/TheConnectarFinal.mp4"
import videoThumbnail from "../assets/images/Video/thumbnail.png";
const Methodology = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);
  
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
 
  useEffect(()=>{
    setTimeout(()=>{
      window.scroll(0,0);
      AOS.init({duration:500, delay:50});
      AOS.refreshHard();
      setTimeout(()=>{
        document.getElementById("footer");
        AOS.refreshHard();
      },1000)
    },100)
    const video = videoRef.current;
    if (video) {
      video.load();
    }
     },[])
  return (
    <>
      <Header />
      <section class="border1">
         <section className="container">
        <section className="row align-items-center" data-aos="fade-right">
        <section class="col-sm-6 bannerContent"  data-aos="fade-right">
              <h2>
                <span className="cor-col">The Connectar </span> Methodology
              </h2>
              <p class="justify-text font">
                The Connectar, is focused on bringing all of the staff within your organisation
                together, so they can finally move as one towards a common goal. To help foster a
                culture where shared ideas, and an eagerness to go above and beyond become the norm
                assisting your company to excel, and achieve the status of being a beacon for great
                places to work. And how do we do this?
              </p>
          </section>
          <section class="col-sm-6 video-container">
        <div class="video-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <video
           ref={videoRef}
           poster={videoThumbnail}
            class="videoframe"
            preload="auto"
            loop
            allow="autoplay; fullscreen;"
            allowfullscreen
          >
            <source src={howItWork} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="play-pause-btn" onClick={togglePlayPause}>
          {isPlaying ? (
          <></>
          ) : (
            <i className="fa fa-play-circle-o video_icon" aria-hidden="true"></i>
          )}
           {isHovered && isPlaying && (
        <div className="pause-icon">
          <i className="fa fa-pause-circle-o video_icon1" aria-hidden="true"></i>
        </div>
      )}
        </div>
        </div>
      </section>
        </section>
        </section>
      </section>
        <section class="container">
          <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6" >
              <img src={culture} alt="" height="400px" width="500px" className="img-fluid" />
            </section>
            <section class="col-sm-6 " >
              <p class="justify-text font">
                Over a number of years, we have developed a proprietary ‘Pollen Pairing’ algorithm,
                which is designed to connect your staff based on what makes them unique, and what’s
                most important to them. We understand your team are busy, so we want to ensure that
                when we connect them they will actually get along which will form a solid foundation
                for a successful connection, which as mentioned leads to a multitude of benefits to
                the employee and your organisation.
              </p>
            </section>
          
          </section>
        </section>
    

      
        <section class="container">
          <section class="row align-items-center" data-aos="fade-right">
           
            <section class="col-sm-6 bannerContent" >
              <p class="justify-text font">
                Our questionnaire focuses on more than just the personality of each employee, but a
                number of other factors which help us to identify their compatibility with each
                other. This information is captured and stored securely using methods of encryption
                and multi factor authentication, and is then processed by our unique algorithm to
                identify which members within your organisation will get along the best.
              </p>
            </section>
            <section class="col-sm-6" >
              <img src={authentication} alt="" height="400px" width="400px" className="img-fluid"/>
            </section>
          </section>
        </section>
     
        <section class="container">
          <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6" >
              <img src={knowledgesharing} alt=""  height="400px" width="500px" className="img-fluid"/>
            </section>
            <section class="col-sm-6 bannerContent" >
              <p class="justify-text font">
                Once we have identified these employees, we then connect them via your preferred
                method , typically email and calendar invite, and they get together for a catch up
                either virtually or in person where possible. The results being your staff may and
                almost certainly will be connecting with other employees they would never ordinarily
                have formed relationships with; leading to improved inclusion, knowledge sharing,
                mentoring and a plethora of additional benefits for all parties involved.{" "}
              </p>
            </section>
            
          </section>
        </section>


      <GetInTouch />
      <div data-aos="fade-right" id="footer">   <Footer /> </div>
    </>
  );
};

export default Methodology;
