import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer';
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
import {  Grid, Box, CircularProgress } from "@mui/material";
import axios from 'axios';
import MDTypography from "components/MDTypography";
import { Link, NavLink,  } from "react-router-dom";
const BlogContent = () => {
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchPages = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/pages/getdata`);
      setPages(response.data);
    } catch (err) {
      console.log("Failed to fetch pages",err);
    }
    finally {
      setLoading(false); 
    }
  };
  useEffect(() => {
    fetchPages();
  }, [apiBaseUrl]);
    useEffect(()=>{
        setTimeout(()=>{
          window.scroll(0,0);
          AOS.init({duration:500, delay:50});
          AOS.refreshHard();
          setTimeout(()=>{
            document.getElementById("footer");
            AOS.refreshHard();
          },1000)
        },100)
         },[])
         const truncateText = (text, maxWords) => {
          const words = text.split(" ");
          return words.length > maxWords
            ? `${words.slice(0, maxWords).join(" ")}...`
            : text;
        };
        const category = window.location.pathname.split('/')[1]; 
  const filteredPages = category
  ? pages.filter(page => page.categories && page.categories.some(cat => cat.toLowerCase() === category)) 
  : pages;
  const isCorporateRoute = location.pathname.startsWith('/corporate');
  const isConferenceRoute = location.pathname.startsWith('/conference');
  const isStudentRoute = location.pathname.startsWith('/student');
  return (
    <>
        <Header />
        <figure >
  <section className="container">
    <section className="row align-items-center" data-aos="fade-right">
    {loading ? (<>
      <Grid item xs={12}>
                <section className="section-widget">
                  <div className="container">
                    <div className="getInTouch text-center p-5" data-aos="fade-right">
                      <MDTypography variant="h5" p={5}>
                        <CircularProgress disableShrink sx={{ color: "#1e90FF" }}/>
                      </MDTypography>
                    </div>
                  </div>
                </section>
              </Grid>
    </>):filteredPages.length === 0 ? (
        <Grid item xs={12}>
          <section className="section-widget">
        <div className="container">
          <div className="getInTouch text-center p-5" data-aos="fade-right">
          <MDTypography variant="h5" p={5}>
        The blog content is currently being prepared. Please check back shortly.
                </MDTypography>
          </div>
        </div>
      </section>
        </Grid>
      ) : (
        filteredPages.map((page, index) => (
        
          <div key={page._id}>
            <section className="row mt-5" data-aos="fade-right">
            <Grid container spacing={2}>
                      {page.featuredImages?.length === 0 ? (
                        <>
                          <Grid item xs={12}>
                            <section>
                              <h2>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: page.title.replace(
                                      page.hightitle,
                                      `<span style="color:${category === "student" ? "#c39e95" : 
                          category === "corporate" ? "#1e90ff" : 
                          category === "conference" ? "#687d6c" : "#333"}">${page.hightitle}</span>`
                                    ),
                                  }}
                                />
                              </h2>
                              <p>{truncateText(page.summary, 30)}</p>
                              {isStudentRoute ? (
  <NavLink   to={`/${category}/${page.slug}`} className="btn-Pink ">
    Read More
  </NavLink>
) : (
  <Link
    to={`/${category}/${page.slug}`}
    className={`btn ${
      isCorporateRoute
        ? "btn-primary"
        : isConferenceRoute
        ? "btn-success"
        : "btn-primary"
    }`}
  >
    Read More
  </Link>
)}

                            </section>
                          </Grid>
                        </>
                      ) : (
                      <>
                            {index % 2 === 0 ? (
                            <>
                              <Grid item xs={12} md={7}>
                                <section>
                                  <h2>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: page.title.replace(
                                          page.hightitle,
                                          `<span style="color:${category === "student" ? "#c39e95" : 
                          category === "corporate" ? "#1e90ff" : 
                          category === "conference" ? "#687d6c" : "#333"}">${page.hightitle}</span>`
                                        ),
                                      }}
                                    />
                                  </h2>
                                  <p>{truncateText(page.summary, 30)}</p>
                                  {isStudentRoute ? (
  <NavLink   to={`/${category}/${page.slug}`} className="btn-Pink ">
    Read More
  </NavLink>
) : (
  <Link
    to={`/${category}/${page.slug}`}
    className={`btn ${
      isCorporateRoute
        ? "btn-primary"
        : isConferenceRoute
        ? "btn-success"
        : "btn-primary"
    }`}
  >
    Read More
  </Link>
)}

                                </section>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                {page.featuredImages[0].endsWith(".mp4") ? (
                                  <video
                                    src={page.featuredImages[0]}
                                    alt="Video"
                                    style={{
                                      height: 250,
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    controls
                                  />
                                ) : (
                                  <img
                                    src={page.featuredImages[0]}
                                    alt="Image"
                                    style={{
                                      height: 250,
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} md={5}>
                                {page.featuredImages[0].endsWith(".mp4") ? (
                                  <video
                                    src={page.featuredImages[0]}
                                    alt="Video"
                                    style={{
                                      height: 250,
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    controls
                                  />
                                ) : (
                                  <img
                                    src={page.featuredImages[0]}
                                    alt="Image"
                                    style={{
                                      height: 250,
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <section>
                                  <h2>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: page.title.replace(
                                          page.hightitle,
                                          `<span style="color:${category === "student" ? "#c39e95" : 
                          category === "corporate" ? "#1e90ff" : 
                          category === "conference" ? "#687d6c" : "#333"}">${page.hightitle}</span>`
                                        ),
                                      }}
                                    />
                                  </h2>
                                  <p>{truncateText(page.summary, 30)}</p>
                                  {isStudentRoute ? (
  <NavLink   to={`/${category}/${page.slug}`} className="btn-Pink ">
    Read More
  </NavLink>
) : (
  <Link
    to={`/${category}/${page.slug}`}
    className={`btn ${
      isCorporateRoute
        ? "btn-primary"
        : isConferenceRoute
        ? "btn-success"
        : "btn-primary"
    }`}
  >
    Read More
  </Link>
)}

                                </section>
                              </Grid>
                            </>
                          )}

</>)}
</Grid>

      
                           
            </section>
          </div>
        ))
      )}
    </section>
  </section>
</figure>

      <div data-aos="fade-right" id="footer"  style={{ marginTop: pages.length === 0 ? "9.2rem" : "10.5rem" }} >       <Footer />     </div>
    </>
  )
}

export default BlogContent

