import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
import Remote from "../../assets/images/Studentpage/RemoteLearning.svg";;
import universityexperience from "../../assets/images/Studentpage/universityexperience.svg";;
import academicsuccess from "../../assets/images/Studentpage/academicsuccess.svg";;
import LongTerm from "../../assets/images/Studentpage/Creativity.svg";
import EmployeeHealth from "../../assets/images/Studentpage/Health.svg";;
import GetTounch from "./GetTounch";

const StudentWhynow = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      <Header />

      {/* Tell us section */}
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>Why you should <span className="heading-color">do something now </span></h2>
              <section class="col-sm-12">
                <p class="justify-text font">
                  For years, there has been an increasing awareness of the importance of connection,
                  especially as universities have embraced digital learning platforms, and students
                  now have the ability to connect virtually from anywhere in the world. Ironically,
                  with this convenience, there has never been a time when students felt more
                  disconnected from one another. True connection — where students feel included,
                  accepted, and part of a community — has become more difficult to achieve.
                </p>
                <div className="col-md-12 order-md-1">
                  <p className="font justify-text">
                    This issue of disconnection isn't just a modern-day challenge for professionals;
                    it's also one that universities must address. If we've learned anything from
                    recent shifts in education, it's that while remote learning is here to stay, it
                    comes with challenges in terms of social engagement, collaboration, and student
                    well-being. Universities that understand the need for fostering stronger
                    connections among their students are ahead of the curve in ensuring that their
                    students feel supported, engaged, and equipped to succeed.
                  </p>
                  <div className="col-md-12 order-md-1">
                    <p className="font justify-text">
                      If your university is wondering how to keep students connected, whether
                      they’re learning remotely, on-campus, or somewhere in between, The Connectar
                      is the perfect solution.
                    </p>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
      {/* baner text section */}

      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={Remote} alt="" style={{height:"300px"}} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                Impact of <span className="heading-color"> Remote Learning</span>
              </h2>
              <p className="font justify-text">
                As more students engage in online or hybrid learning environments, maintaining a
                sense of connection among peers has become increasingly difficult. Remote students
                can often feel isolated from their classmates, leading to a drop in morale,
                engagement, and even academic performance. Addressing these challenges is vital to
                ensuring students stay motivated, connected, and supported throughout their academic
                journey.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center order-md-2">
              <img src={universityexperience} alt="" style={{height:"300px"}} className="img-fluid" />
            </div>
            <div className="col-md-7 order-md-1">
              <h2>
                Changing Expectations <span className="heading-color">of Students</span>
              </h2>
              <p className="font justify-text">
                Today’s students have different expectations regarding their university experience.
                Beyond academics, they seek a sense of community, meaningful connections with peers,
                and support for their mental health and well-being. Universities that fail to
                prioritize connection risk seeing lower levels of student satisfaction, higher
                dropout rates, and lower engagement in extracurricular activities. Fostering
                connections can create an inclusive, engaging environment that not only attracts top
                students but also supports them throughout their academic careers.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={academicsuccess} alt="" style={{height:"300px"}} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-color">Competitive </span> Landscape
              </h2>
              <p className="font justify-text">
                {" "}
                In today’s competitive academic landscape, universities that foster strong student
                connections have a significant advantage. A connected student body leads to higher
                levels of student satisfaction, retention, and academic success. By focusing on
                improving connections, universities can build a stronger, more cohesive campus
                culture that sets them apart from other institutions. Delaying action on this issue
                could result in students choosing universities that offer stronger networks and a
                more supportive environment.
              </p>{" "}
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center order-md-2">
              <img src={LongTerm} alt="" style={{height:"300px"}} className="img-fluid" />
            </div>
            <div className="col-md-7 order-md-1">
              <h2>
                Long-Term <span className="heading-color"> Success</span>
              </h2>
              <p className="font justify-text">
                Investing in fostering student connection isn't just about addressing immediate
                challenges; it's also about setting up a university community for long-term success.
                Universities that invest in building strong social and academic networks for their
                students are more likely to see better academic performance, increased retention
                rates, and graduates who remain loyal to their alma mater. These students are more
                likely to contribute to the university's reputation and success as they progress in
                their careers and lives.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={EmployeeHealth} alt="" style={{height:"300px"}} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-color"> Student Well-being </span> and Mental Health
              </h2>
              <p className="font justify-text">
                {" "}
                The importance of student mental health and well-being has never been clearer. The
                pressures of university life, combined with feelings of isolation, can contribute to
                anxiety, stress, and burnout. Students who feel connected to their peers and
                supported by their university community experience fewer mental health struggles.
                Taking action to improve student connection is about ensuring students not only
                succeed academically but thrive emotionally and socially. The well-being of students
                is directly tied to their sense of community and connection on campus.
                <br />
                By prioritizing connection now, universities can set the foundation for a more
                engaged, successful, and mentally healthy student body. The Connectar is here to
                help make that vision a reality, creating meaningful connections that support
                students throughout their university journey and beyond.
              </p>{" "}
            </div>
          </div>
        </section>
      </section>

      <GetTounch />

      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default StudentWhynow;
