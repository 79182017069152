import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "assets/images/small-logos/Logo-removebg-preview.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./event.css";
const Header = () => {
  const location = useLocation();
  useEffect(() => {
    AOS.init({ duration: 3000 });
    window.scrollTo(0, 0);
  }, [location]);
const isConferenceRoute = location.pathname !== ('/conference');
  return (
    <>
      <section className="circle circle1"></section>
      <section className="circle circle2"></section>
      <header id="header">
        <nav
          className="navbar navbar-expand-lg fixed-top navbar-light"
          style={{ backgroundColor: "#ffffff", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
        >
          <div className="container" data-aos="fade-down">
            {/* {isConferenceRoute ? (
              <NavLink to="/conference" className="navbar-brand">
              <img src={Logo} alt="The Connectar" height="300px" width="300px" />
            </NavLink>
            ):( */}
              <NavLink to="/" className="navbar-brand">
                <img src={Logo} alt="The Connectar" height="300px" width="300px" />
              </NavLink>
            {/* )} */}
            
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                         <ul className="navbar-nav me-auto mb-2 mb-lg-0 font">
                         <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/home">
                              Home
                             </NavLink>
                           </li>
                           <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/why-the-connectar">
                               Why Connectar{" "}
                             </NavLink>
                           </li>
                           <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/why-you-should">
                               Data Insights
                             </NavLink>
                           </li>
                           <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/methodology">
                               {" "}
                                Methodology
                             </NavLink>
                           </li>
                           <li>
                             <NavLink
                               className={({ isActive }) =>
                                isActive ? "nav-link active-success" : "nav-link"
                              }
                               to="/conference/whynow"
                             >
                               Why Act Now
                             </NavLink>
                           </li>
                           <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/blogs" >Blogs</NavLink>
                           </li>
                           <li className="nav-item">
                             <NavLink  className={({ isActive }) =>
                      isActive ? "nav-link active-success" : "nav-link"
                    } to="/conference/contact">
                               Contact 
                             </NavLink>
                           </li>
                         </ul>
                         <div className="d-flex" role="search">
                           <NavLink className="btn btn-outline-success me-3" to="/conference/login">
                             Login
                           </NavLink>
                         </div>
                       </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
