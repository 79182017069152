import React, { useEffect } from "react";
import EventbriteSurvey from "../../assets/images/confrence/EventbriteSurvey.svg";
import Satisfaction from "../../assets/images/confrence/Satisfaction.svg";
import linkdin from "../../assets/images/confrence/linkdin.svg";
import wellbeingImage from "../../assets/images/confrence/confwellbeing.svg";
import StrongerAttendee from "../../assets/images/confrence/StrongerAttendee.svg";
import EventImpact from "../../assets/images/confrence/EventImpact.svg";
import Customer from "../../assets/images/confrence/Customer.svg";
import References from "../../assets/images/confrence/References.svg";
import Higher from "../../assets/images/confrence/Higherreport.svg";
import SuccessMetrics from "../../assets/images/confrence/SuccessMetrics.svg"
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./event.css";
import GetConfrenecTounch from "./GetInTouch";
const ConfrenceWhatTellUs = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      <Header />
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                {" "}
                What Does <span className="heading-col">The Data Tell Us?</span>{" "}
           
              </h2>
              <h3>     The Power of Connection at{" "}
              <span className="heading-col">Conferences and Events </span></h3>
              <p className="font justify-text">
                The benefits of fostering meaningful connections at conferences and events are
                backed by extensive research and data. Studies consistently show that networking and
                collaboration are not only essential for attendee satisfaction but also for the
                long-term success of both individuals and organizations. From enhancing engagement
                and knowledge sharing to boosting innovation and reducing stress, the data
                underscores the pivotal role that connections play in making events more impactful.
                By leveraging the power of networking, event organizers can create more valuable
                experiences, ensuring greater satisfaction, retention, and business outcomes. Below
                are key insights that demonstrate the measurable benefits of prioritizing attendee
                connection at your next event.
              </p>
            </section>
          </section>
        </section>
      </section>

      <section class="content-widget">
        <section class="container">
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={EventbriteSurvey} alt="" />
            </section>
            <section class="col-md-7">
              <h2>
                1. Increased <span className="heading-col">Event Engagement:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>Eventbrite Survey:</b> According to a 2019 survey by Eventbrite, 68% of
                    attendees report that networking is one of the most valuable aspects of any
                    event. The survey highlighted that attendees who make meaningful connections are
                    not only more engaged during the event but are also more likely to return for
                    future events
                    <ul>
                      <li>
                        <b>Source: Eventbrite,</b> "The Value of Networking at Conferences",2019.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Satisfaction} alt="" style={{height:"320px"}}/>
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                2. Higher Satisfaction with <span className="heading-col">Event Experience:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>Harvard Business Review:</b> A study published by Harvard Business Review
                    found that 70% of conference attendees who networked and engaged with their
                    peers reported higher levels of satisfaction with their overall event
                    experience. They also felt that the value of the event increased significantly
                    when they made strong personal connections.
                    <ul>
                      <li>
                        <b>Source: Harvard Business Review,</b> "The Impact of Employee Engagement
                        on Performance", 2016.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={linkdin} alt=""  style={{height:"250px"}}/>
            </section>
            <section class="col-md-7">
              <h2>
                3. Better <span className="heading-col">Networking Outcomes :</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>LinkedIn Survey:</b> LinkedIn's research revealed that 85% of professionals
                    believe face-to-face networking at conferences and events is crucial for career
                    advancement. The survey further found that 58% of professionals said they
                    secured new job opportunities through networking at events.
                    <ul>
                      <li>
                        <b>Source: LinkedIn,</b> "How Networking Affects Career Growth," 2020.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={wellbeingImage} alt="" style={{height:"250px"}}/>
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                4. Increased <span className="heading-col"> Knowledge Sharing:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>McKinsey & Company:</b> McKinsey's study showed that effective networking at
                    conferences leads to a 20-25% increase in collaboration and knowledge-sharing
                    between participants. This enhances not only individual professional development
                    but also fosters innovation by bringing diverse perspectives together
                    <ul>
                      <li>
                        <b>Source: McKinsey & Company,</b> "The Social Economy," 2012.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={StrongerAttendee} alt="" style={{height:"300px"}}/>
            </section>
            <section class="col-md-7">
              <h2>
                5. Stronger <span className="heading-col"> Attendee Retention:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>Deloitte Survey:</b> MA 2019 Deloitte report found that attendees who were
                    able to form strong professional relationships at events were 3 times more
                    likely to return to future conferences hosted by the same organization. These
                    attendees also indicated they were more likely to recommend the event to others.
                    <ul>
                      <li>
                        <b>Source: Deloitte ,</b> "The Employee Experience: It’s Time We All Take It
                        Seriously," 2019.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={EventImpact} alt="" style={{height:"300px"}}/>
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                6. Event Impact on <span className="heading-col">Long-Term Relationships:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b> Bizzabo Report:</b> Research by Bizzabo indicates that 89% of event
                    organizers consider networking to be one of the most important event objectives.
                    Furthermore, 78% of attendees said they formed long-term business relationships
                    at events, which contributed to long-lasting professional growth.
                    <ul>
                      <li>
                        <b>Source: Bizzabo ,</b> "Event Marketing 2020: What’s New in Attendee
                        Engagement," 2020.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={Higher} alt="" style={{height:"320px"}}/>
            </section>
            <section class="col-md-7">
              <h2>
                7.Higher
                <span className="heading-col"> ROI for Attendees :</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>Cvent Report:</b> Cvent’s annual event industry report found that 83% of
                    event professionals believe networking is the primary value for attendees, and
                    91% of attendees who networked at events reported a high return on investment
                    (ROI) from their participation in terms of professional connections and business
                    opportunities.
                    <ul>
                      <li>
                        <b>Source: Cvent ,</b> "2020 Annual Event Industry Trends," 2020.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Customer} alt="" style={{height:"300px"}} />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                8. Increased Innovation through{" "}
                <span className="heading-col"> Diverse Connections:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b>Stanford University:</b> Research by Stanford highlights that individuals who
                    network and collaborate across industries during conferences are 40% more likely
                    to develop innovative ideas and solutions. The study emphasizes that diversity
                    in networking creates fertile ground for creative problem-solving .
                    <ul>
                      <li>
                        <b>Source: Stanford University ,</b> "Creativity and Collaboration: The
                        Impact of Interdisciplinary Engagement," 2017.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={References} alt="" style={{height:"200px"}}/>
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                9. <span className="heading-col"> Reduced Stress and Burnout for Attendees:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b> Psychology of Events:</b> A study published in the Journal of Applied
                    Psychology found that attending networking sessions at conferences can
                    significantly reduce stress for professionals, especially when they have the
                    opportunity to engage in positive and supportive interactions with peers.
                    <ul>
                      <li>
                        <b>Source: Journal of Applied Psychology ,</b> "Networking and Professional
                        Burnout," 2018.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>

          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={SuccessMetrics} alt="" height="300px" width="400px" />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                10. Enhanced Event <span className="heading-col"> Success Metrics:</span>
              </h2>
              <p className="justify-text font">
                <ul>
                  <li>
                    <b> PCMA (Professional Convention Management Association):</b> According to
                    PCMA’s 2020 report, events that focus on creating meaningful connections through
                    networking saw a 40% increase in attendee satisfaction and a 30% increase in
                    overall event success metrics, including revenue and return attendance.
                    <ul>
                      <li>
                        <b>Source: Professional Convention Management Association (PCMA) ,</b> "The
                        2020 Meeting and Event Industry Study," 2020.
                      </li>
                      <li>
                        These data insights emphasize the powerful impact of networking and
                        connection-building at conferences and events. By fostering an environment
                        that encourages attendees to engage with each other, event organizers can
                        significantly enhance the overall experience, leading to higher
                        satisfaction, stronger professional networks, and long-term success for all
                        involved.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
        </section>
      </section>

      {/* <section class="section-widget">
        <section class="container">
          <section class="getInTouch text-center mt-5" data-aos="fade-right">
            <p className="font">
              
            </p>
            <a href="/Contact" class="btn btn-success">
              Get in touch
            </a>
          </section>
        </section>
      </section> */}
      <GetConfrenecTounch />
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default ConfrenceWhatTellUs;
